.app__footer {
  flex: 1;
  width: 100%;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.app__footer-cards {
  width: 60%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin: 4em 2rem 1rem;

  .app__footer-card {
    min-width: 290px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    // margin: 1rem 0;
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
    background-color: var(--white-color);
    

    transition: all 0.3s ease-in-out;

    img {
      width: 40px;
      height: 40px;
      margin: 0 0.7rem;
    }

    p {
      font-weight: 500;
    }
    a {
      text-decoration: none;
      font-weight: 500;
    }
    &:hover {
      box-shadow: 0 0 25px #daf1f6;
    }

    @media screen and (max-width: 450px) {
      width: 100%;
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.app__footer-form {
  width: 56%;
  flex-direction: column;
  margin: 1rem 2rem;

  div {
    width: 100%;

    margin: 0.75rem 0;
    border-radius: 10px;
    cursor: pointer;
    background-color: var(--primary-color);

    transition: all 0.3s ease-in-out;

    input,
    textarea {
      width: 100%;
      padding: 0.95rem;
      border: none;
      border-radius: 7px;
      background-color: #FEFBF6;
      background-color: var(--white-color);
      color: var(--secondary-color);
      outline: none;
    }

    textarea {
      height: 80px;
    }

    &:hover {
      box-shadow: 0 0 25px var(--primary-color);
    }
  }

  button {
    padding: 1rem 2rem;
    border-radius: 10px;
    border: none;
    background-color: var(--secondary-color);

    font-weight: 500;
    color: var(--white-color);
    outline: none;
    margin: 1rem 0 0 0;
    font-family: var(--font-base);

    transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    cursor: pointer;

    &:hover {
      background-color: #2430af;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 1rem 0;
  }
}

// 
// 
// 

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  color: #333;
  padding: 20px;
  width: 100%;
}

.footer__right {
  text-align: right;
}

.social-icons {
  margin: 0;
  padding: 0;
  list-style: none;
}

.social-icons li {
  display: inline;
  margin-left: 10px;
}

.social-icons a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #0d1d76;
}

.social-icons a:hover {
  background-color: #111111;
}


@media (max-width: 400px) {
  .footer__right {
    text-align: center;
    justify-content: center;
    width: 300px;
  }
  p{
font-size: 14px !important;
  }
}